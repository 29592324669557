<template>
	<div class="mt-4">
		<v-sheet color="orange lighten-1" rounded="xl">
			<v-breadcrumbs :items="foldersHistory" large class="py-3">
				<template v-slot:divider>
					<v-icon>mdi-chevron-right</v-icon>
				</template>
				<template v-slot:item="{ item }">
					<v-breadcrumbs-item @click="goBack(item)" style="cursor: pointer">
						{{ item.name }}
					</v-breadcrumbs-item>
				</template>
			</v-breadcrumbs>
		</v-sheet>

		<v-row class="my-1">
			<v-col>
				<ExplorerUploadMenu />
				<ExplorerCreateMenu />
			</v-col>
			<v-col v-if="!$vuetify.breakpoint.smAndDown">
				<ExplorerReportProblemMenu />
			</v-col>
		</v-row>

		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto rounded-xl" />
			</template>

			<template v-else-if="!folders.length && !files.length">
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="48" role="img"> mdi-emoticon-dead-outline </v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
				</div>
			</template>

			<template v-else>
				<Listing :key="'transition-skeleton'" />
			</template>
		</v-fade-transition>

		<v-row>
			<v-col v-if="$vuetify.breakpoint.smAndDown">
				<ExplorerReportProblemMenu />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('courses.explorer')} - ${this.course.name}`,
		}
	},
	components: {
		Listing: () => import('@/components/explorer/Listing.vue'),
		ExplorerReportProblemMenu: () => import('@/components/explorer/ExplorerReportProblemMenu.vue'),
		ExplorerUploadMenu: () => import('@/components/explorer/ExplorerUploadMenu.vue'),
		ExplorerCreateMenu: () => import('@/components/explorer/ExplorerCreateMenu.vue'),
	},
	computed: {
		...mapGetters({
			course: 'courses/course',
			folders: 'explorer/folders',
			files: 'explorer/files',
			foldersHistory: 'explorer/foldersHistory',
			loading: 'explorer/loading',
		}),
	},
	created() {
		if (this.foldersHistory.length && this.course.folderID !== this.foldersHistory[0]['id']) {
			this.fetchFolder({ course: this.course, folder: { name: '', id: '' } })
		} else if (this.foldersHistory.length && this.course.folderID === this.foldersHistory[0]['id']) {
			this.fetchFolder({
				course: this.course,
				folder: { name: this.foldersHistory[this.foldersHistory.length - 1].name, id: this.foldersHistory[this.foldersHistory.length - 1].id },
			})
		} else {
			this.fetchFolder({ course: this.course, folder: { name: '', id: '' } })
		}
	},
	watch: {
		course() {
			this.fetchFolder({ course: this.course, folder: { name: '', id: '' } })
		},
	},
	methods: {
		activeFolder(folderID) {
			if (this.foldersHistory.length) {
				return this.foldersHistory[this.foldersHistory.length - 1].id === folderID
			} else {
				return false
			}
		},
		goBack(folder) {
			if (this.foldersHistory.length) {
				const index = this.foldersHistory.indexOf(folder)
				if (index > -1) {
					this.HISTORY_GOBACK(index)
				}
				this.fetchFolder({ course: this.course, folder })
			}
		},
		...mapActions('explorer', ['fetchFolder']),
		...mapMutations('explorer', ['HISTORY_GOBACK']),
	},
}
</script>
